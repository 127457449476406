import React, { FC } from 'react';
import { ReactComponent as SpinnerIcon } from '../../assets/icons/icon-spinner.svg';
import './SuspenseFallback.scss';

const SuspenseFallback: FC = () => {
  return (
    <div className='spin-container'>
      <SpinnerIcon />
    </div>
  );
};

export default SuspenseFallback;
